import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Brand from 'components/elements/Vectors/Brand'

// Images
import Circle from 'img/circle.svg'

// Video
import Intro from 'img/intro.webm'
import { MutableRefObject } from 'hoist-non-react-statics/node_modules/@types/react'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.dark};

  @media (min-width: 1200px) {
    height: 80vh;
    min-height: 700px;
    max-height: 700px;
  }

  @media (min-width: 992px) {
    margin-bottom: 7.5rem;
  }

  @media (max-width: 991px) {
    margin-bottom: 5.5rem;
  }
`

const BrandWrapper = styled.div`
  & svg {
    opacity: 0.1;
  }

  @media (min-width: 992px) {
    top: 0;
    right: 0;
  }

  @media (max-width: 991px) {
    top: 20%;
    right: 0;
  }
`

const BannerWrapper = styled.div`
  position: relative;
`

const Row = styled.div`
  padding-top: 7rem;
`

const ImageWrapper = styled.div`
  margin-top: -6rem;

  @media (min-width: 992px) {
    right: -169px;
  }

  @media (max-width: 991px) {
    right: 45px;
  }
`

const Image = styled(Plaatjie)`
  @media (min-width: 992px) {
    height: 800px;
    width: 912px;
  }

  @media (max-width: 991px) {
    height: 370px;
    width: 350px;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};

  & ul {
    list-style: none;
    li {
      position: relative;
      margin-left: 0.25rem;
      margin-bottom: 1rem;
      font-size: ${({ theme }) => theme.font.size.big};
      &:before {
        position: absolute;
        left: -35px;
        top: 0;
        content: url(${Circle});
        width: 14px;
        height: 14px;
      }
    }
  }

  @media (min-width: 992px) {
    padding-right: 9rem;
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      margin-bottom: 2.5rem;
      font-size: ${({ theme }) => theme.font.size.huge} !important;
      line-height: ${({ theme }) => theme.font.size.mega} !important;
    }
  }

  @media (max-width: 991px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      margin-bottom: 1.5rem;
    }
  }
`

const Video = styled.video`
  @media (max-width: 575px) {
    min-height: 100vh;
  }
`

const BannerLanding: React.FC<BannerProps> = ({ fields }) => {
  const [videoEnded, setVideoEnded] = useState(false)
  const [hovered, setHovered] = useState(false)
  const videoRef = useRef() as MutableRefObject<HTMLVideoElement>

  const setPlayBack = (playbackRate: number) => {
    videoRef.current.playbackRate = playbackRate
  }

  return (
    <Section className="position-relative">
      {!videoEnded && (
        <Video
          ref={videoRef}
          width="100%"
          height="100%"
          playsInline
          autoPlay
          muted
          preload="metadata"
          src={Intro}
          onEnded={() => setVideoEnded(true)}
          onCanPlay={() => setPlayBack(1.1)}
        />
      )}
      {videoEnded && (
        <>
          <BrandWrapper className="position-absolute">
            <Brand id="banner-brand" width={868} height={874} />
          </BrandWrapper>
          <BannerWrapper>
            <div className="container py-5">
              <Row className="row justify-content-center">
                <div className="col-lg-11">
                  <div className="row ">
                    <div className="col-lg-5 d-flex justify-content-center justify-content-lg-end order-lg-2">
                      <ImageWrapper className="position-relative">
                        <motion.div
                          onHoverStart={() => setHovered(true)}
                          onHoverEnd={() => setHovered(false)}
                          initial={{ scale: 1 }}
                          animate={hovered ? { scale: 1.05 } : { scale: 1 }}
                        >
                          <Image alt="banner" image={fields?.image} />
                        </motion.div>
                      </ImageWrapper>
                    </div>
                    <div className="col-lg-7 order-lg-1">
                      <Content content={fields.description} />
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </BannerWrapper>
        </>
      )}
    </Section>
  )
}

export default BannerLanding
